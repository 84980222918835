import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import App from './layouts/App';
import './assets/app.scss';
import './assets/img/logo-large.jpg';
import './assets/img/favicon.ico';
import './assets/img/gym.jpg';
import './assets/img/classes.jpg';
import './assets/img/classes.jpeg';
import './assets/img/kids.jpg';
import './assets/img/lisa.jpeg';
import './assets/img/jo.png';
import './assets/img/jo-pt.jpg';
import './assets/img/robert.jpeg';
import './assets/img/janey.jpeg';
import './assets/img/celebrate.jpg';
import './assets/img/personal-training.jpg';
import './assets/img/fb.jpg';
import './assets/img/gym-dark.jpg';
import './assets/img/gym-dark-large.jpeg';
import './assets/img/gym-light-large.jpg';
import './assets/img/logo.trans.svg';
import './assets/img/gym-light.jpeg';
import './assets/img/gym-photo-1.png';
import './assets/img/gym-photo-2.png';
import './assets/img/gym-photo-3.png';
import './assets/img/gym-photo-4.png';
import './assets/img/gym-photo-5.png';
import './assets/img/gym-photo-6.png';
import './assets/img/gym-photo-7.png';
import './assets/img/gym-photo-8.png';
import './assets/img/gym-photo-9.png';
import './assets/img/gym-photo-10.png';
import './assets/img/gym-photo-11.png';
import './assets/img/pt.png';
import './assets/img/pt.jpg';
import './assets/img/pt-logo.jpg';
import './assets/img/christmas.png';
import './assets/img/summer.png';
import './assets/img/summer-alt.png';
import './assets/img/background.jpeg';
import './assets/img/gymnastics.jpeg';
import './assets/img/mike.jpeg';
import './assets/img/sarah.jpeg';
import './assets/img/logo.svg';
import './assets/img/about-us.jpeg';
import './assets/img/santa.jpg';
import './assets/img/paypal.svg';
import './assets/img/liz.jpeg';
import './assets/img/gymnastics-equipment.jpeg';
import './assets/img/vault.jpeg';
import './assets/img/spin.jpeg';
import './assets/img/weights.jpeg';
import './assets/img/court-hire.jpeg';
import './assets/img/gymnastics-gallery-1.jpeg';
import './assets/img/gymnastics-gallery-1-thumb.jpeg';
import './assets/img/gymnastics-gallery-2.jpeg';
import './assets/img/gymnastics-gallery-2-thumb.jpeg';
import './assets/img/gymnastics-gallery-3.jpeg';
import './assets/img/gymnastics-gallery-3-thumb.jpeg';
import './assets/img/gymnastics-gallery-4.jpeg';
import './assets/img/gymnastics-gallery-4-thumb.jpeg';

export const app = props => {
  // Grab the state from a global variable injected into the server-generated HTML
  const preloadedState = window.PRELOADED_STATE;

  // Allow the passed state to be garbage-collected
  delete window.PRELOADED_STATE;

  // Create Redux store with initial state
  const store = configureStore(preloadedState);

  return hydrate(
    <Provider store={store}>
      <App {...props} />
    </Provider>,
    document.getElementById('react-body')
  );
};
