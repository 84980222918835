/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Formik } from 'formik';
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Modal from './Modal';
import { setActiveBooking } from '../store/actions/bookings';
import { isGymAndClassesMember } from '../utils/bookings';
import { oneTimePlans } from '../utils/consts';

axios.defaults.withCredentials = true;

export const DAYS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

const mapStateToProps = state => ({
  activeBooking: get(state, 'bookings.activeBooking'),
  user: get(state, 'user'),
});

const mapDispatchToProps = dispatch => ({
  clearBooking: () => dispatch(setActiveBooking(null)),
});

const flexerciseClasses = [];

const shouldDisableNon10amClasses = (dateString, activeBooking) => {
  const { startHour } = activeBooking;

  // allow flexercise
  if (flexerciseClasses.includes(dateString)) {
    if (startHour === 11) {
      return false;
    }
  }

  return false;
};

const octoberHolidays = [];

const shouldDisablOctoberHolidays = (dateString, startHour) => {
  return octoberHolidays.includes(dateString);
};

export function disableDays(date, activeBooking) {
  const formattedDate = date.format('DD/MM/YYYY');

  const { day, startHour } = activeBooking;

  // cancel stay strong with liz
  if (
    (formattedDate === '20/01/2025' || formattedDate === '22/01/2025') &&
    startHour === 11
  ) {
    return true;
  }

  return (
    date.day() !== DAYS[day] ||
    shouldDisablOctoberHolidays(formattedDate, startHour) ||
    shouldDisableNon10amClasses(formattedDate, activeBooking)
  );
}

export const theme = createTheme({
  palette: {
    primary1Color: '#27b8d9',
    primary: { main: '#1b1819' },
    secondary: { main: '#27b8d9' },
    type: 'light',
  },
});

export function TextFieldWrap(props) {
  const { inputRef, value, onClick, onBlur } = props;
  return (
    <input
      className="text-input--disabled"
      id="booking-date"
      ref={inputRef}
      type="text"
      placeholder="Select booking date"
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      readOnly
    />
  );
}

function Spinner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 38 38"
      stroke="#1b1819"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
}

function initPayPalButton({ setPayment, formNode }) {
  window.paypal
    .Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'paypal',
      },
      // onClick is called when the button is clicked
      onClick: () => {
        // Show a validation error if the checkbox is not checked
        if (!document.getElementById('booking-date').value.length) {
          alert('Please select a booking date');
        }
      },
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: 'The Gym at Moffat Class',
              amount: { currency_code: 'GBP', value: 6 },
            },
          ],
        });
      },
      onApprove: (_, actions) => {
        return actions.order.capture().then(details => {
          setPayment(details);
          formNode.current.submitForm();
        });
      },
      onError: err => {
        console.log(err);
      },
    })
    .render('#modal-paypal-button-container');
}

export const renderMembershipError = ({ user }) => {
  if (oneTimePlans.includes(user.membershipLevel)) {
    return (
      <p className="gs-u-mb+">
        Hey! It looks like your Membership has run out or you've not completed
        payment. Please get in touch with us to get up and running.
      </p>
    );
  }

  if (user.membershipLevel === 'summer') {
    return (
      <p className="gs-u-mb+">
        Hey! It looks like your Summer Membership has run out. We hope you had
        the best summer!
      </p>
    );
  }

  const paymentLink = user.partnerMemberId
    ? `/memberships/${user._id}/${user.partnerMemberId}/payment`
    : `/memberships/${user.membershipId}/payment`;

  return (
    <>
      <p className="gs-u-mb+">
        Hey! It looks like your membership payments have stopped.
      </p>
      <p className="gs-u-mb+">
        Please setup your payment details again before proceeding to book
        classes.
      </p>
      <a
        className="button button--filled button--full button--large"
        href={paymentLink}
      >
        Proceed to payment
      </a>
    </>
  );
};

/**
 * BookingsModal component
 *
 * @param {object} props
 */
const BookingsModal = ({ activeBooking, user, clearBooking }) => {
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingMinDate] = useState(new Date('January 6, 2025 00:01:00'));
  const [bookingMaxDate] = useState(new Date('March 30, 2025 00:01:00'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payment, setPayment] = useState(null);
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const formNode = useRef();

  const setupPayPalButton = () => {
    const buttonContainer = document.getElementById(
      'modal-paypal-button-container'
    );

    if (buttonContainer && !paypalLoaded) {
      initPayPalButton({ setPayment, formNode });
      setPaypalLoaded(true);
    }
  };

  const onModalClose = () => {
    setBookingStartDate(null);
    setIsSubmitting(false);
    setPaypalLoaded(false);
    clearBooking();
  };

  if (user && user.hasInvalidMembershipSubscription) {
    return (
      <Modal onClose={onModalClose}>
        <React.Fragment>
          <h2 className="gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
            B O O K I N G
          </h2>
          {renderMembershipError({ user })}
        </React.Fragment>
      </Modal>
    );
  }

  if (activeBooking && user) {
    const { label, timeLabel } = activeBooking;
    return (
      <Modal onClose={onModalClose}>
        <React.Fragment>
          <h2 className="gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
            B O O K I N G
          </h2>
          <Formik
            initialValues={{}}
            innerRef={formNode}
            onSubmit={async () => {
              setIsSubmitting(true);

              if (!bookingStartDate) {
                alert('Please choose a date.');
                setIsSubmitting(false);
                return;
              }

              const {
                startHour,
                startMinutes,
                endHour,
                endMinutes,
              } = activeBooking;
              bookingStartDate.hour(startHour);
              bookingStartDate.minute(startMinutes);
              bookingStartDate.second(0);

              const bookingEndDate = bookingStartDate.clone();
              bookingEndDate.hour(endHour);
              bookingEndDate.minute(endMinutes);

              const booking = {
                userId: user._id,
                email: user.email,
                firstName: user.firstName,
                surName: user.surName,
                username: user.username,
                membershipType: user.membershipType,
                membershipLevel: user.membershipLevel,
                subscriptionId: user.subscriptionId,
                phone: user.phone,
                class: activeBooking,
                bookingStartDate: bookingStartDate.format(),
                bookingStartDateEpoch: bookingStartDate.valueOf(),
                bookingEndDate: bookingEndDate.format(),
                bookingEndDateEpoch: bookingEndDate.valueOf(),
              };

              if (!isGymAndClassesMember(user) && !payment) {
                return window.alert('Please pay via PayPal');
              }

              if (!isGymAndClassesMember(user) && payment) {
                booking.payment = payment;
              }

              const { data } = await axios
                .post('/api/bookings', booking)
                .catch(err => {
                  setIsSubmitting(false);
                  const { data: errData } = err.response;
                  console.log(errData);

                  if (errData.error) {
                    return window.alert(errData.error);
                  }

                  if (errData.message) {
                    return window.alert(errData.message);
                  }

                  return window.alert(
                    'Looks like something went wrong there. Please try again.'
                  );
                });
              setIsSubmitting(false);

              if (data && data.success) {
                window.location.href =
                  '/classes?m=Your booking is now confirmed. You should receive a confirmation email.';
              }
            }}
          >
            {props => {
              const { handleChange, handleSubmit, handleBlur } = props;
              return (
                <form onSubmit={handleSubmit} className="gel-layout">
                  <fieldset className="gel-layout__item form-element form-element__control gel-1/1 gs-u-mb+">
                    <i className="fa fa-user form-element__icon" />
                    <input
                      id="userName"
                      name="userName"
                      type="text"
                      placeholder={`${user.firstName} ${user.surName}`}
                      value={`${user.firstName} ${user.surName}`}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-input text-input--disabled"
                      disabled
                    />
                  </fieldset>
                  <fieldset className="gel-layout__item form-element form-element__control gel-1/1 gs-u-mb+">
                    <i className="fa fa-info-circle form-element__icon" />
                    <input
                      id="bookingClassName"
                      name="bookingClassName"
                      type="text"
                      placeholder={label}
                      value={label}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-input text-input--disabled"
                      disabled
                    />
                  </fieldset>

                  <fieldset className="gel-layout__item form-element form-element__control gel-1/1 gs-u-mb+">
                    <i className="fa fa-clock-o form-element__icon" />
                    <input
                      id="bookingClassTime"
                      name="bookingClassTime"
                      type="text"
                      placeholder={timeLabel}
                      required
                      value={timeLabel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-input text-input--disabled"
                      disabled
                    />
                  </fieldset>

                  <fieldset className="gel-layout__item form-element form-element__control gel-1/1 gs-u-mb+">
                    <i className="fa fa-calendar form-element__icon" />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <ThemeProvider theme={theme}>
                        <DatePicker
                          variant="inline"
                          format="dddd, Do MMMM"
                          value={bookingStartDate}
                          minDate={bookingMinDate}
                          maxDate={bookingMaxDate}
                          onChange={e => {
                            setBookingStartDate(e);
                            setupPayPalButton();
                          }}
                          onBlur={handleBlur}
                          shouldDisableDate={date =>
                            disableDays(date, activeBooking)
                          }
                          TextFieldComponent={TextFieldWrap}
                          disablePast
                          autoOk
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </fieldset>

                  {isGymAndClassesMember(user) && (
                    <fieldset className="gel-layout__item gs-u-mb gel-1/1">
                      <button
                        className="button button--filled button--full button--large"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Confirm booking
                      </button>
                    </fieldset>
                  )}

                  {!isGymAndClassesMember(user) && (
                    <fieldset className="gel-layout__item gs-u-mb gel-1/1">
                      {!paypalLoaded && (
                        <button
                          className="button button--filled button--full button--large"
                          type="submit"
                          disabled
                        >
                          Pay with PayPal
                        </button>
                      )}
                      <div id="smart-button-container">
                        <div>
                          <div id="modal-paypal-button-container" />
                        </div>
                      </div>
                      <span className="label-info gs-u-mt gel-minion gs-u-display-block">
                        Once you return from PayPal, please wait for your
                        payment to process.
                      </span>
                    </fieldset>
                  )}
                </form>
              );
            }}
          </Formik>
          {isSubmitting && (
            <div className="modal__spinner">
              <Spinner />
            </div>
          )}
        </React.Fragment>
      </Modal>
    );
  }
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsModal);
