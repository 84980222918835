/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import TextSection from '../../components/TextSection';

/**
 * Teens component
 *
 * @param {object} props
 */
const Teens = () => {
  return (
    <Fragment>
      <Banner
        title="Teenagers at The Gym at Moffat"
        imageUrl="/assets/img/vault.jpeg"
      />
      <TextSection
        textHtml={
          <div className="gel-pica" style={{ textAlign: 'left' }}>
            <p className="gs-u-mb+">
              <b>Rules</b>
              <span className="gs-u-display-block gs-u-mt+">
                Under 16's must be accompanied by an adult and must have an
                induction and permission/training from the manager for free
                weights.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b className="gs-u-display-block gs-u-mb+">Hours</b>
              <span>
                Monday to Thursday from 4pm to 7pm, staff will be there to
                supervise. Wednesday from 4pm to 5pm is teen hour where we offer
                free training and advice from a PT.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b className="gs-u-display-block gs-u-mb+">Classes</b>
              <span>
                All classes are open for teens to join with an interactive,
                darkened spin room if you feel self conscious and want to feel
                immersed and comfortable.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b className="gs-u-display-block gs-u-mb+">Court hire</b>
              <span>
                Free for our teen members to play badminton, basketball, indoor
                football, pickleball and more. Adult supervision is required.
              </span>
            </p>
          </div>
        }
      />
    </Fragment>
  );
};

export default Teens;
