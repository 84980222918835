import React from 'react';
import PropTypes from 'prop-types';
import Home from './app/Home';
import AboutUs from './app/AboutUs';
import Memberships from './app/Memberships';
import MembershipsChoice from './app/MembershipsChoice';
import CouplesPayment from './app/CouplesPayment';
import Payment from './app/Payment';
import PaymentSuccess from './app/PaymentSuccess';
import Classes from './app/Classes';
import Register from './app/Register';
import Success from './app/Success';
import Admin from './app/Admin';
import AdminBookings from './app/AdminBookings';
import AdminMembers from './app/AdminMembers';
import AdminUsers from './app/AdminUsers';
import Bookings from './app/Bookings';
import MyBookings from './app/MyBookings';
import PAYG from './app/PAYG';
import PersonalTraining from './app/PersonalTraining';
import Gymnastics from './app/Gymnastics';
import Header from '../containers/Header';
import Sidebar from '../containers/Sidebar';
import Notification from '../components/Notification';
import Footer from '../components/Footer';
import RegisterMember from '../containers/RegisterMember';
import MyAccount from '../containers/MyAccount';
import RegisterNonMember from '../containers/RegisterNonMember';
import ChildConsent from '../containers/ChildConsent';
import Login from '../containers/Login';
import PersonalTrainingEnquiry from '../containers/PersonalTrainingEnquiry';
import CouplesMemberships from './app/CouplesMemberships';
import CouplesMembershipsDetails from './app/CouplesMembershipsDetails';
import CourtHire from './app/CourtHire';
import JustGiving from './app/JustGiving';
import Teens from './app/Teens';

const navItems = [
  {
    icon: 'info-circle',
    href: '/',
    text: 'About us',
  },
  {
    icon: 'user-plus',
    href: '/',
    text: 'Memberships',
  },
  {
    icon: 'users',
    href: '/',
    text: 'Classes',
  },
  {
    icon: 'credit-card',
    href: '/',
    text: 'Pay as you go',
  },
  {
    icon: 'heartbeat',
    href: '/',
    text: 'Personal training',
  },
  {
    icon: 'book',
    href: '/',
    text: 'Bookings',
  },
];

/**
 * This is the entry point layout component
 *
 * @param {object} props
 */
const App = ({ page, level, notificationMessage, errorMessage, ptrt }) => {
  const renderLayout = () => {
    switch (page) {
      case 'about-us':
        return <AboutUs />;
      case 'payment-success':
        return <PaymentSuccess />;
      case 'memberships':
        return <Memberships level={level} />;
      case 'memberships-choice':
        return <MembershipsChoice />;
      case 'couples-memberships':
        return <CouplesMemberships />;
      case 'couples-memberships-details':
        return <CouplesMembershipsDetails />;
      case 'payment':
        return <Payment />;
      case 'couplesPayment':
        return <CouplesPayment />;
      case 'classes':
        return <Classes />;
      case 'register':
        return <Register />;
      case 'register-member':
        return <RegisterMember />;
      case 'register-non-member':
        return <RegisterNonMember />;
      case 'success':
        return <Success />;
      case 'child-consent':
        return <ChildConsent />;
      case 'login':
        return <Login ptrt={ptrt} />;
      case 'my-account':
        return <MyAccount />;
      case 'admin':
        return <Admin />;
      case 'bookings':
        return <Bookings />;
      case 'admin-bookings':
        return <AdminBookings />;
      case 'admin-members':
        return <AdminMembers />;
      case 'admin-users':
        return <AdminUsers />;
      case 'my-bookings':
        return <MyBookings />;
      case 'personal-training':
        return <PersonalTraining />;
      case 'personal-training-enquiry':
        return <PersonalTrainingEnquiry />;
      case 'gymnastics':
        return <Gymnastics />;
      case 'courtHire':
        return <CourtHire />;
      case 'payg':
        return <PAYG />;
      case 'just-giving':
        return <JustGiving />;
      case 'teens':
        return <Teens />;
      default:
        return <Home />;
    }
  };

  return (
    <div id="outer-container" className="content gs-u-box-size">
      <Sidebar />
      <Notification message={notificationMessage} errorMessage={errorMessage} />
      <div id="page-wrap">
        <Header
          page={page}
          isSmall={!(page === 'home') && !(page === 'personal-training')}
          navItems={navItems}
        />
        {renderLayout()}
        <Footer />
      </div>
    </div>
  );
};

App.defaultProps = {
  level: null,
  notificationMessage: null,
  errorMessage: null,
};

App.propTypes = {
  page: PropTypes.string.isRequired,
  notificationMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  level: PropTypes.string,
};

export default App;
